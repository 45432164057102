import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Circle } from "react-leaflet";
import { useModal } from "../../hooks/modal";
import { FarmMap } from "./widgets/map";
import { NewFarmModal } from "./widgets/farmSelector";

export const InitialFarmSetup = () => {
    const { t } = useTranslation();
    const { openModal } = useModal();

    useEffect(() => {
        openModal(
            t("shared:farms.title-create-farm"),
            <NewFarmModal />,
            null,
            { maxWidth: "sm", fullWidth: true }
        );
    }, []);

    return (<div id="farm_registration">
        <FarmMap />
    </div>);
};

import axios from "axios";
import { useAuthContext } from "../../authContext";
import { useLoading } from "../../loadingContext";

function getAppDomainName() {
    const subdomains = window.location.hostname.split(".");
    if (subdomains[1] === "agricircle")    // production
        return `https://app.${subdomains.slice(1).join(".")}/api`;
    return `https://${subdomains.slice(1).join(".")}/api`;
}

export const apiUrl = getAppDomainName();

export const useAsyncApi = () => {
    const { profile, setProfile } = useAuthContext();
    const { startLoading, doneLoading } = useLoading();

    const sendRequest = async (method, url, requestData, headers, ignore401, asBlob) => {
        const config = {
            baseURL: apiUrl,
            url,
            method,
            headers: headers || { "content-type": "application/json" },
            withCredentials: true
        };
        if (requestData != null) {
            config.data = requestData;
            if (requestData instanceof FormData)
                delete config.headers["content-type"];
        }
        if (asBlob) {
            config.responseType = "blob";
        }
        startLoading();
        try {
            const response = await axios(config);
            return { status: response.status, data: response.data, isError: response.status >= 400, headers: {...response.headers} };
        } catch (e) {
            if (!ignore401 && e.response?.status == 401) {
                setProfile({ role: profile?.role, status: 401 });
            }
            return { status: e.response?.status || 500, isError: true, data: e.response?.data };
        }
        finally {
            doneLoading();
        }
    };

    return sendRequest;
};

export * from "./jsonapi";

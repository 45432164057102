import { useState, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MenuItem, TextField, InputAdornment, DialogContent, DialogActions, Button, Autocomplete, Typography } from "@mui/material";
import { debounce } from '@mui/material/utils'
import { NewActionButton, DeleteActionButton } from "../../../widgets/actionIcons"
import { setSelectedFarm, reloadFarms } from "../../redux/reducer";
import { selectFarm, selectFarms } from "../../redux/selectors";
import { useFarmApi } from "../../hooks";
import { useModal } from "../../../hooks/modal";
import { useValidation, NOT_EMPTY_VALIDATOR, NAME_VALIDATOR } from "../../../hooks/validation";
import { findLocations } from "../../../utils/googleMaps";

export const FarmSelector = ({ onChange, canCreate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const farms = useSelector(selectFarms);
    const farm = useSelector(selectFarm);
    const farmApi = useFarmApi();
    const singleFarm = farms?.length < 2;

    if (!farms) return null;

    function handleFarmCreate() {
        openModal(
            t("shared:farms.title-create-farm"),
            <NewFarmModal />,
            null,
            { maxWidth: "sm", fullWidth: true }
        );
    }

    function handleFarmDelete() {
        const validator = (v) => (v === t("shared:label-confirm-delete")) ? null : "";
        prompt(
            t("shared:farms.title-confirm-delete-farm"),
            () => {
                farmApi.deleteFarm(farm.id, () => dispatch(reloadFarms()));
            },
            { label: t("shared:farms.text-confirm-delete-farm"), action: t("shared:btn-delete"), validator }
        )
    }

    function handleFarmChange(e) {
        const farmId = e.target.value;
        dispatch(setSelectedFarm(farmId));
        onChange(farmId);
    }

    return (<TextField
        value={singleFarm ? (farm?.name || "-") : (farm?.id || "-")}
        helperText={t("shared:farms.label-num-fields", { count: farm?.fields?.length || "-" })}
        onChange={handleFarmChange}
        select={!singleFarm}
        margin="dense"
        variant="standard"
        sx={{ width: "30ch" }}
        data-cy="farm-selector"
        slotProps={{
            input: {
                readOnly: singleFarm && !canCreate,
                endAdornment: canCreate
                    ? <InputAdornment position="end">
                        {(!singleFarm || null) && <DeleteActionButton onClick={handleFarmDelete} />}
                        <NewActionButton onClick={handleFarmCreate} style={{ marginRight: "15px" }} />
                    </InputAdornment>
                    : undefined
            }
        }}
    >
        {farms.map((f) => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)}
    </TextField>);
};



export const NewFarmModal = () => {
    const { closeModal } = useModal();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const VALIDATION_OPTIONS = {
        name: {
            validator: [
                NOT_EMPTY_VALIDATOR,
                NAME_VALIDATOR(t("shared:validation-length", { min: 2, max: 32 }))
            ]
        },
        location: {
            NOT_EMPTY_VALIDATOR
        }
    };
    const validation = useValidation(VALIDATION_OPTIONS);
    const farmApi = useFarmApi();
    const [farm, setFarm] = useState({ name: "", location: null });
    const [googleLocation, setGoogleLocation] = useState(null);

    useEffect(() => {
        validation.init(farm);
    }, []);


    function handleChange(e) {
        const prop = e.target.name;
        const value = validation.validate(prop, e.target.value);
        if (value !== undefined) {
            setFarm({ ...farm, [prop]: value });
        }
    }

    function handleLocationChange(gLoc) {
        if (!gLoc) {
            validation.validate("location", null);
            setFarm({ ...farm, location: null });
            setGoogleLocation(null);
            return;
        }
        const loc = { type: "Point", coordinates: [gLoc.geometry.location.lng, gLoc.geometry.location.lat] }
        setGoogleLocation(gLoc);
        validation.validate("location", loc);
        setFarm({ ...farm, location: loc });
    }

    function handleCreate() {
        farmApi.createFarm({ name: farm.name, location: farm.location }, (farm) => {
            closeModal();
            dispatch(reloadFarms());
            dispatch(setSelectedFarm(farm.id));
        });
    }

    return (<>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
                label={t("shared:farms.label-farm-name")}
                name="name"
                required
                value={farm.name || ""}
                onChange={handleChange}
                error={!validation.isValid("name")}
                helperText={validation.error("name")}
                margin="dense"
                variant="standard"
                autoComplete="off"
                autoFocus
                fullWidth
            />
            <GoogleLocation value={googleLocation} onChange={handleLocationChange} />
        </DialogContent>
        <DialogActions>
            <Button
                variant="outlined"
                onClick={closeModal}
            >
                {t("shared:btn-cancel")}
            </Button>
            <Button
                variant="contained"
                onClick={handleCreate}
                disabled={!validation.isModified() || !validation.isValid()}
            >
                {t("shared:btn-ok")}
            </Button>
        </DialogActions>
    </>);
};


const GoogleLocation = ({ value, onChange }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);

    const fetch = useMemo(() => debounce((request, callback) => {
        findLocations(request, callback);
    }, 400), []);

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(inputValue, (loc, results) => {
            if (active) {
                let newOptions = value ? [value] : [];
                if (results?.length) newOptions = [...newOptions, ...results];
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (<Autocomplete
        id="farm-locator"
        getOptionLabel={(option) =>
            typeof option === "string" ? option : option.formatted_address
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        fullWidth
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={t("shared:farms.text-no-locations")}
        onChange={(e, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            onChange(newValue);
        }}
        onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                margin="dense"
                variant="standard"
                placeholder={t("shared:farms.text-locate-farm")}
                error={!value}
            />
        )}
        renderOption={(props, option, attributes) => {
            return (<li {...props} key={attributes.index}>
                <Typography variant="body2" color="text.secondary">
                    {option.formatted_address}
                </Typography>
            </li>);
        }}
        size="small"
    />);
};
